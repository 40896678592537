'use strict'

/**
 * パスワードの処理を提供します
 */
export default () => {
  const func = {
    INPUT: document.querySelectorAll('.js-password-input'),
    BUTTON: document.querySelectorAll('.js-password-btn'),

    /**
     * init
     */
    init: () => {
      func.BUTTON.forEach(btn => {
        btn.addEventListener('click', () => {
          func.switchShowHide(btn)
        })
      })
    },

    /**
     * show
     */
    show: (target) => {
      target.previousElementSibling.type = 'text'
      target.classList.add('is-show')
    },

    /**
     * hide
     */
    hide: (target) => {
      target.previousElementSibling.type = 'password'
      target.classList.remove('is-show')
    },

    /**
     * switchShowHide
     */
    switchShowHide: (target) => {
      target.classList.contains('is-show') ? func.hide(target) : func.show(target)
    }
  }

  func.init()
}
