'use strict'

import EL from '../constant/elements'
import pD from './preventDefault'
import getDeviceType from './getDeviceType'
import { throttle, debounce } from 'throttle-debounce'

/**
 * ハンバーガーメニューの処理を提供します
 */
export default () => {
  const func = {
    isActive: false,
    deviceType: getDeviceType(),

    HMB: document.querySelector('.l-hmb'),
    HMBBG: document.querySelector('.l-hmb-bg'),

    /**
     * init
     */
    init: () => {
      if (func.HMB) {
        func.HMB.addEventListener('click', func.switchShowHide, false)
        func.HMBBG.addEventListener('click', func.switchShowHide, false)
        window.addEventListener('resize', func.resize, false)
      }
    },

    /**
     * show
     */
    show: () => {
      if (EL.HTML.classList.contains('is-search-active')) EL.HTML.classList.remove('is-search-active')
      func.isActive = true
      EL.NAV.style.visibility = ''
      EL.HTML.classList.add('is-nav-active')
    },

    /**
     * hide
     */
    hide: () => {
      func.isActive = false
      EL.HTML.classList.remove('is-nav-active')
    },

    /**
     * switchShowHide
     */
    switchShowHide: () => {
      func.isActive ? func.hide() : func.show()
    },

    /**
     * resize
     */
    resize: debounce(150, () => {
      if (func.deviceType !== getDeviceType()) {
        func.deviceType = getDeviceType()
        func.hide()

        if (func.deviceType === 'lg') {
          EL.NAV.style.visibility = ''
        }
      }
    })

  }

  func.init()
}
