'use strict'
import 'swiper/css/swiper.min.css'
import Swiper from 'swiper'
import { throttle, debounce } from 'throttle-debounce'

/**
 * topページの処理を提供します
 */

export default () => {
  const mvSwiper = new Swiper('.top__mv', {
    slidesPerView: 'auto',
    centeredSlides: true,
    effect: 'slide',
    loop: true,
    speed: 500,
    autoplay: {
      delay: 5000
    },
    pagination: {
      el: '.top__mv--pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.top__mv--next',
      prevEl: '.top__mv--prev'
    }
  })

  const rankItems = document.querySelectorAll('.c-product-list--ranking')
  const rankSwiper = Array(document.querySelectorAll('.c-product-list--ranking').length)
  rankItems.forEach(function (elem, index) {
    if (window.innerWidth <= 768) {
      rankSwiper[index] = new Swiper(elem, {
        slidesPerView: 'auto',
        effect: 'slide',
        speed: 500,
        pagination: {
          el: elem.querySelector('.swiper-pagination'),
          clickable: true
        }
      })
    }
  })
  window.addEventListener('resize',
    debounce(150, () => {
      rankItems.forEach(function (elem, index) {
        if (window.innerWidth <= 768) {
          if (rankSwiper[index]) {

          } else {
            rankSwiper[index] = new Swiper(elem, {
              slidesPerView: 'auto',
              effect: 'slide',
              speed: 500,
              pagination: {
                el: elem.querySelector('.swiper-pagination'),
                clickable: true
              }
            })
          }
        } else {
          if (rankSwiper[index]) {
            rankSwiper[index].destroy()
            rankSwiper[index] = undefined
          }
        }
      })
    }),
    false
  )
}
