'use strict'

export default () => {
	const event = new CustomEvent('favorite-change');

	/*
	*　お気に入り商品登録Ajax
	*/
	function registerFavorite(elem,gcd) {
		
		var def = $.Deferred();

		// Ajax通信を行う
		$.ajax({
			type     : "GET"
			,url      : pkg_common.getAppComplementUrl() + "/getFavoriteRegisterResponse"
			,dataType : "json"
			,data     :  {"gcd":gcd}
			,timeout  : 30000
		})
			.done(function(data){favoriteRegistAjax_getFavoriteRegisterResponse(elem,data);})
			.fail(function(data){favoriteRegistAjax_getFavoriteRegisterResponse(elem,data);})
		;
		return def.promise();
	}

	function favoriteRegistAjax_getFavoriteRegisterResponse(elem,response) {
		//response is null
		if (response == null || response == undefined || response == '') {
			handleFavoriteRegistOperationFail();
		}

		for (var i = 0; i < response.length; i++) {
			var favoriteMap = response[i];
			var favoriteOperation = favoriteMap.favoriteRegistSuccess;
			var gcd = favoriteMap.gcd;
			if("false" == favoriteOperation) {
				handleFavoriteRegistOperationFail(gcd);
			} else if("true" == favoriteOperation) {
				handleFavoriteRegistOperationSuccess(gcd);
				elem.dispatchEvent(event);
			}
		}
	}

	window.registerFavorite = registerFavorite;
}