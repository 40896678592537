'use strict'

/**
 * フォームの入力項目の連動を制御
 */
export default () => {
  const func = {

    el: document.querySelectorAll('[data-relation-inputs="el"]'),
    childs: document.querySelectorAll('[data-relation-inputs="child"]'),

    /**
     * init
     */
    init: () => {
      if (!func.el) return
      func.disableChild()
      func.el.forEach(el => {
        const parent = el.querySelector('[data-relation-inputs="parent"]')
        const child = el.querySelector('[data-relation-inputs="child"]')

        if (!parent || !child) return
        parent.addEventListener('change', e => {
          func.resetChild(child)
        }, false)
      })
    },

    /**
     * resetChild
     */
    resetChild: (child) => {
      func.childs.forEach(el => {
        const select = el.querySelectorAll('select')
        const input = el.querySelectorAll('input')
        const selectDiv = el.querySelectorAll('.c-selectbox')
        if (el === child) {
          select.forEach(el => {
            el.disabled = false
          })
          input.forEach(el => {
            el.disabled = false
          })
          selectDiv.forEach(el => {
            el.classList.remove('disable')
          })
        } else {
          select.forEach(el => {
            el.disabled = true
            //el.selectedIndex = 0
          })
          input.forEach(el => {
            el.disabled = true
            //el.checked = false
            //el.value = ''
          })
          selectDiv.forEach(el => {
            func.resetSelectBox(el)
          })
          selectDiv.forEach(el => {
            el.classList.add('disable')
            el.classList.remove('is-open')
          })
        }
      })
    },

    /**
     * disableChild
     */
    disableChild: () => {
      func.el.forEach(el => {
        const parent = el.querySelector('[data-relation-inputs="parent"]')
        const child = el.querySelector('[data-relation-inputs="child"]')

        if (!parent || !child) return
        if( !parent.checked ) {
          child.querySelectorAll('select').forEach(el => {
            el.disabled = true
          })
          child.querySelectorAll('input').forEach(el => {
            el.disabled = true
          })
          child.querySelectorAll('.c-selectbox').forEach(el => {
            el.classList.add('disable')
          })
        }
      })
    },

    /**
     * resetSelectbox
     */
    resetSelectBox: (el) => {
      if (el.querySelector('.c-selectbox__list li.is-select')) el.querySelector('.c-selectbox__list li.is-select').classList.remove('is-select')

      const def = el.querySelector('.c-selectbox__list li')
      def.classList.add('is-select')

      el.querySelector('.c-selectbox--text').innerHTML = def.querySelector('.c-selectbox--text').innerHTML
    }
  }

  func.init()
}
