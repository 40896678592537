'use strict'
import 'swiper/css/swiper.min.css'
import Swiper from 'swiper'
import { throttle, debounce } from 'throttle-debounce'

/**
 * 検索結果ページの処理を提供します
 */

export default () => {
  // イシューNo09対
  // document.querySelector('.js-view-change').addEventListener('change', (e) => {
  //   if (e.target.value === 'list') location.href = '/search/list/'
  //   if (e.target.value === 'thumb') location.href = '/search/'
  // })
}
