'use strict'

/**
 * セレクトボックスの処理を提供します
 */
export default () => {
  const func = {
    SELECTBOX: document.querySelectorAll('.c-selectbox'),

    /**
     * init
     */
    init: () => {
      func.SELECTBOX.forEach(SBOX => {
        SBOX.querySelector('.c-selectbox__display').addEventListener('click', () => {
          func.switchShowHide(SBOX)
        })
        SBOX.querySelector('.c-selectbox__list').addEventListener('click', () => {
          func.switchShowHide(SBOX)
        })
        SBOX.querySelectorAll('.c-selectbox__list li').forEach(LIST => {
          LIST.addEventListener('click', () => {
            const selectIndex = [].slice.call(SBOX.querySelectorAll('.c-selectbox__list li')).indexOf(LIST)
            SBOX.querySelector('select').selectedIndex = selectIndex
            func.setListItem(SBOX)
          })
        })
        func.setListItem(SBOX)
      })
    },

    /**
     * switchShowHide
     */
    switchShowHide: (targetBox) => {
      targetBox.classList.contains('is-open') ? targetBox.classList.remove('is-open') : targetBox.classList.add('is-open')
    },

    /**
     * setListItem
     */
    setListItem: (targetBox) => {
      const displayTarget = targetBox.querySelector('.c-selectbox__display')

      // 初期化
      if (targetBox.querySelector('.c-selectbox__list li.is-select')) targetBox.querySelector('.c-selectbox__list li.is-select').classList.remove('is-select')
      if (displayTarget.querySelector('.c-selectbox--icon')) displayTarget.querySelector('.c-selectbox--icon').remove()
      if (displayTarget.querySelector('.c-selectbox--text')) displayTarget.querySelector('.c-selectbox--text').remove()

      // インデックス取得
      const selectIndex = targetBox.querySelector('select').selectedIndex
      const displayItem = targetBox.querySelectorAll('.c-selectbox__list li')[selectIndex]

      // 表示アイテム
      const displayText = displayItem.querySelector('.c-selectbox--text').cloneNode(true)
      const displayIcon = displayItem.querySelector('.c-selectbox--icon')
      displayTarget.insertBefore(displayText, displayTarget.firstChild)
      if (displayIcon) {
        displayTarget.insertBefore(displayIcon.cloneNode(true), displayTarget.firstChild)
      }

      // リストアイテム
      displayItem.classList.add('is-select')
    }
  }

  func.init()
}
