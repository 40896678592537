'use strict'
import 'swiper/css/swiper.min.css'
import Swiper from 'swiper'
import { throttle, debounce } from 'throttle-debounce'

$(function(){
  var bodyclass = $('body').attr('class');
  if(bodyclass === 'top topjq'){
    console.log(bodyclass);
      // Ajaxを順番に実行
    $.when(callAjax('/section1.html'))
    .then(function() {
        return callAjax('/section2.html');
    })
    .then(function() {
        return callAjax('/section3.html');
    });

    $(window).on('resize',function(){
      $('#categoryRankingArea .c-product-list--ranking').each(function(){
        var rankslide;
        if (window.innerWidth < 900) {
          rankslide = new Swiper(this, {
            slidesPerView: 'auto',
            effect: 'slide',
            speed: 500,
            observer: true,
            pagination: {
              el: $(this).find('.swiper-pagination'),
              clickable: true
            }
          });
        } else {
          if(this.swiper !== undefined){
            this.swiper.destroy(true,true);
            this.swiper = undefined;
          }
        }
      });
    });
  }
});

function callAjax(dataurl){
  //非同期処理順序制御
  var def = $.Deferred();

  // Ajax通信を行う
  $.ajax({
    type     : "GET"
    ,url      : dataurl
    ,dataType:'html'
    ,timeout  : 30000
  })
    .done(function(data){
      sethtml(data,def);
    })
    .fail(function(data){
      console.log('fail');
    })
  ;
  return def.promise();
}

function sethtml(contents,def) {
  $('#categoryRankingArea').append(contents);
  var slideclass = $(contents).find('.c-product-list--ranking').attr('class');
  //console.log(slideclass);
  var elem = '[class="' + slideclass + '"]';
  if (window.innerWidth < 900) {
    var rankslide = new Swiper(elem, {
      slidesPerView: 'auto',
      effect: 'slide',
      speed: 500,
      observer: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
    })
  }
  def.resolve();
}
