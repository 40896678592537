'use strict'

/**
 * お気に入りボタンの処理を提供します
 */
export default () => {
  const func = {
    FAVBTN: document.querySelectorAll('.js-btn-favorite'),

    /**
     * init
     */
    init: () => {
      func.FAVBTN.forEach(btn => {
        btn.addEventListener('favorite-change', (e) => {
          func.switchShowHide(btn)
        })
        btn.addEventListener('click', (e) => {
          e.preventDefault()
        })
      })
    },

    /**
     * show
     */
    show: (btn) => {
      btn.classList.add('is-active')
    },

    /**
     * hide
     */
    hide: (btn) => {
      btn.classList.remove('is-active')
    },

    /**
     * switchShowHide
     */
    switchShowHide: (btn) => {
      btn.classList.contains('is-active') ? func.hide(btn) : func.show(btn)
    }

  }

  func.init()
}
