'use strict'

/**
 * アコーディオンの処理を行います
 */
export default () => {
  document.querySelectorAll('.js-accordion-btn').forEach(btn => {
    btn.addEventListener('click', () => {
      btn.closest('.l-nav-slide--btn').classList.contains('is-opened') ? accordionHide(btn) : accordionShow(btn)
    })
  })

  const getHeight = target => {
    return target.clientHeight
  }

  function accordionShow (btn) {
    const btnParent = btn.closest('.l-nav-slide--btn')
    const panel = btnParent.nextElementSibling
    btnParent.classList.add('is-opened')
    panel.classList.add('is-opened')
    if (btnParent.closest('li').dataset.hierarchy > 1) {
      const addHeight = getHeight(panel.firstElementChild)
      for (let i = 1; i < btnParent.closest('li').dataset.hierarchy; ++i) {
        const parentPanel = btnParent.closest('[data-hierarchy="' + i + '"]').querySelector('.js-accordion-block')
        parentPanel.style.height = Number(parentPanel.style.height.replace('px', '')) + addHeight + 'px'
      }
    }
    panel.style.height = getHeight(panel.firstElementChild) + 'px'
  }
  function accordionHide (btn) {
    const btnParent = btn.closest('.l-nav-slide--btn')
    const panel = btnParent.nextElementSibling
    btnParent.classList.remove('is-opened')
    panel.classList.remove('is-opened')
    if (btnParent.closest('li').dataset.hierarchy > 1) {
      const minusHeight = getHeight(panel.firstElementChild)
      for (let i = 1; i < btnParent.closest('li').dataset.hierarchy; ++i) {
        const parentPanel = btnParent.closest('[data-hierarchy="' + i + '"]').querySelector('.js-accordion-block')
        parentPanel.style.height = Number(parentPanel.style.height.replace('px', '')) - minusHeight + 'px'
      }
    }
    panel.style.height = 0
  }
}
