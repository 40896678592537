'use strict'

// babel polyfill
import '@babel/polyfill'

//smooth-scroll-polyfill
import "scroll-behavior-polyfill";

// define
import EL from './constant/elements'

// helper
import closetPolyfill from './helper/polyfillCloset'
import hmb from './helper/hmb'
import search from './helper/search'
import smSearch from './helper/sm-search'
import uaDataset from './helper/uaDataset'
import sweetScrollInit from './helper/sweetScrollInit'
import getDocumentH from './helper/getDocumentHeight'
import isTouchSupport from './helper/isTouchSupport'
import getOrientation from './helper/getOrientation'
import getClassName from './helper/getClassName'
import getDeviceType from './helper/getDeviceType'
import accordion from './helper/accordion'
import selectbox from './helper/selectbox'
import relationInputs from './helper/relationInputs'
import favorite from './helper/favorite'
import password from './helper/password'
import anchor from './helper/anchor'
import setViewHeight from './helper/setViewHeight'

// plugins
import objectFitImages from 'object-fit-images'
import picturefill from 'picturefill'
import Stickyfill from 'stickyfilljs'
import { throttle, debounce } from 'throttle-debounce'
import 'nodelist-foreach-polyfill'
import MatchHeight from 'matchheight'

// page scripts
import pageNameTop from './page/top'
import pageNameFavoriteRegist from './page/favoriteRegist'
import pageNameProductDetail from './page/product-detail'
import pageNameCart from './page/cart'
import pageNameSearch from './page/search'
//jquerytest
//import jqTest from './page/top_jquery'
require('./page/top_jquery.js');
require('svgxuse')

// getDeviceType
let deviceType = getDeviceType()

// getDocumentH
let documentH = getDocumentH()

// setViewHeight
setViewHeight()

/**
 * getScrollPos
 */
const getScrollPos = () => {
  const y = window.pageYOffset

  // add class is-scroll
  if (y > 1) {
    if (!EL.HTML.classList.contains('is-scroll')) {
      EL.HTML.classList.add('is-scroll')
    }
  } else {
    EL.HTML.classList.remove('is-scroll')
  }

  // add class is-footer
  if (documentH <= y) {
    if (!EL.HTML.classList.contains('is-footer')) {
      EL.HTML.classList.add('is-footer')
    }
  } else {
    EL.HTML.classList.remove('is-footer')
  }
}

/**
 * first
 */
const first = () => {
  // set ua dataset
  uaDataset()

  // set touch support dataset
  isTouchSupport()

  // closet polyfill.
  closetPolyfill()

  // Math height
  MatchHeight.init()

  // Polyfill object-fit
  objectFitImages()

  // Polyfill picturefill
  picturefill()

  // stickyfilljs
  Stickyfill.add(EL.STICKY)

  // getOrientation
  getOrientation()

  // hmb
  hmb()

  // search modal
  search()
  smSearch()

  // accordion
  accordion()

  // selectbox
  selectbox()

  // favorite
  favorite()

  // password
  password()

  // sweetScroll
  sweetScrollInit()

    // anchor
  anchor()

}

/**
 * init
 */
const init = () => {
  // get body className
  const className = getClassName(EL.BODY)

  // add .is-loaded
  EL.HTML.classList.add('is-loaded')

  // getScrollPos
  getScrollPos()

  // top
  if (className.endsWith('top')) {
    pageNameTop()
  }
  // topjq jquery読み込む版
  if (className.endsWith('topjq')) {
    pageNameTop()
  }

  // product-detail
  if (className.endsWith('product-detail')) {
    pageNameFavoriteRegist()
    pageNameProductDetail()
    require('webpack-jquery-ui/dialog')
  }

  // mypage
  if (className.endsWith('mypage')) {
    require('webpack-jquery-ui/dialog')
  }

  // cart
  if (className.endsWith('cart')) {
    pageNameCart()
    pageNameFavoriteRegist()
  }

  //form
  relationInputs()

  // search
  if (className.endsWith('search')) {
    pageNameSearch()
  }
}

/**
 * DOMCONTENTLOADED
 */
window.addEventListener('DOMContentLoaded', first)

/**
 * LOAD
 */
window.addEventListener('load', init)

/**
 * SCROLL
 */
window.addEventListener('scroll', throttle(150, getScrollPos), false)

/**
 * RESIZE
 */
window.addEventListener('resize',
  debounce(150, () => {
    // LGとSMで切り替わる時
    if (deviceType !== getDeviceType()) {
      deviceType = getDeviceType()

      // documentH更新
      documentH = getDocumentH()
    }
  }),
  false
)
